.PI {
  margin: 0px;
  padding: 0px;
  margin-top: 5px;
  word-break: break-all;
}

.fonts {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.fontBox {
  border-style: solid;
  border-width: 0.05rem;
  border-color: black;
  padding: 10px 40px 10px 40px;
  background-color: none;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 2.5%;
}

.font {
  font-size: 24px;
}

a:visited {
  color: black;
}

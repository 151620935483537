* {
  padding: 0px;
  margin: 0px;
}

input,
textarea,
button,
select,
p,
a {
  -webkit-tap-highlight-color: transparent;
}

:root {
  --dark-color: #181818;
  --light-color: #f2f2f2;
}

.text {
  color: black;
  font-family: 'Avenir';
  font-size: 18px;
}

.full-page {
  display: flex;
  width: 100vw;
  flex-direction: row;
}

.content {
  width: 100vw;
  transition: 500ms ease-out;
  border: none;
  outline: none;
}

.row {
  display: flex;
  flex-direction: row;
}
.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

.disable-selection {
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}

.pointer {
  cursor: pointer;
}

.rotate {
  -moz-transition: all 250ms linear;
  -webkit-transition: all 250ms linear;
  transition: all 250ms linear;
}
.rotate.down {
  -moz-transform: rotate(150deg);
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
}
